import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../components/faq";
import ShowroomSection from "../components/showroom-section";
import ServiceHero from "../components/service-hero";
import ImageSide from "../components/image-side";
import OpeningBanner from "../components/opening-banner";
import ThreeImageSection from "../components/three-image-section";
import Reviews from "../components/reviews";
import PriceListCarousal from "../components/price-list-carousal";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";
import uuid4 from "uuid4";

const AboutUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
        reviewAuthor
        reviewDate
        reviewContent
      }
      schemaLogo: wpMediaItem(title: { eq: "tops-logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "greenhouses" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        greenhousesPageFieldGroups {
          greenhousesBannerSection {
            heading
            description
            cta1 {
              title
              target
              url
            }
            backgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
            image {
              node {
                altText
                mediaItemUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          greenhousesFeaturesSection {
            heading
            description
            features {
              description
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          greenhousesTwoColumnSection1 {
            heading
            leftColumn {
              heading
              description
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
            rightColumn {
              heading
              brochureImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
              brochureLink {
                target
                title
                url
              }
              priceListLink {
                target
                title
                url
              }
              priceListImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          greenhousesTwoColumnSection2 {
            heading
            description
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          greenhousesPerfectionSection {
            heading
            description
            cta {
              title
              target
              url
            }
            images {
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          greenhousesOpeningHoursSection {
            heading
          }
          greenhousesReviewsSection {
            heading
          }
          greenhousesFaqSection {
            heading
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          greenhousesCtaSection1 {
            heading
            description
            cta {
              target
              title
              url
            }
          }
          greenhousesTwoColumnSection3 {
            heading
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;

  const {
    pageData: { seoFieldGroups, greenhousesPageFieldGroups },
  } = data;

  const {
    greenhousesBannerSection,
    greenhousesFeaturesSection,
    greenhousesTwoColumnSection1,
    greenhousesTwoColumnSection2,
    greenhousesPerfectionSection,
    greenhousesOpeningHoursSection,
    greenhousesReviewsSection,
    greenhousesCtaSection1,
    greenhousesTwoColumnSection3,
    greenhousesFaqSection,
  } = greenhousesPageFieldGroups;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Greenhouses",
        item: {
          url: `${siteUrl}/greenhouses`,
          id: `${siteUrl}/greenhouses`,
        },
      },
    ],
  };

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": "https://www.topsgardenbuildings.com/greenhouses",
    image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: "https://www.topsgardenbuildings.com/greenhouses",
    sku: "https://www.topsgardenbuildings.com/greenhouses",
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
    name: "Tops Garden Buildings",
    url: "https://www.topsgardenbuildings.com",
    brand: {
      "@type": "Organization",
      logo: `https://www.topsgardenbuildings.com${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.topsgardenbuildings.com${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Tops Garden Buildings",
      url: "https://www.topsgardenbuildings.com",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: "125",
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },

    review: {
      "@type": "Review",
      url: "https://www.topsgardenbuildings.com",
      datePublished: `${data.reviews.reviewDate}`,
      reviewBody: `${data.reviews.reviewContent}`,
      author: {
        "@type": "Person",
        name: `${data.reviews.reviewAuthor}`,
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "4.9",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Tops Garden Buildings",
        sameAs: "https://www.topsgardenbuildings.com",
      },
    },
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/greenhouses`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {greenhousesBannerSection &&
            !checkPropertiesForNull(greenhousesBannerSection, ["heading"]) && (
              <ServiceHero
                backgroundImage={
                  greenhousesBannerSection.backgroundImage.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                title={greenhousesBannerSection?.heading}
                description={
                  <SafeHtmlParser
                    htmlContent={greenhousesBannerSection?.description}
                  />
                }
                ctaButton={greenhousesBannerSection?.cta1}
                image={greenhousesBannerSection?.image.node}
              />
            )}

          {greenhousesFeaturesSection &&
            !checkPropertiesForNull(greenhousesFeaturesSection, [
              "heading",
            ]) && (
              <section className="py-5 py-lg-7">
                <Container>
                  <Row className="mb-5">
                    <Col>
                      <h2 className="  mb-4 text-primary  ">
                        {greenhousesFeaturesSection?.heading}
                      </h2>
                      <SafeHtmlParser
                        htmlContent={greenhousesFeaturesSection?.description}
                      />
                    </Col>
                  </Row>
                  {greenhousesFeaturesSection?.features &&
                    greenhousesFeaturesSection?.features.length > 0 && (
                      <Row className="g-5">
                        {greenhousesFeaturesSection?.features.map((item) => (
                          <Col lg={4} md={6} key={uuid4()}>
                            <Row>
                              <Col xs={2}>
                                <GatsbyImage
                                  className=""
                                  quality="100"
                                  image={
                                    item.image.node?.localFile.childImageSharp
                                      .gatsbyImageData
                                  }
                                  altText={item.image.node?.altText}
                                  placeholder="blurred"
                                  style={{ maxWidth: "50px" }}
                                  formats={["auto", "webp"]}
                                />
                              </Col>
                              <Col xs={10}>
                                <div className="pb-0 mb-0">
                                  <SafeHtmlParser
                                    htmlContent={item?.description}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        ))}
                      </Row>
                    )}
                </Container>
              </section>
            )}
          {greenhousesTwoColumnSection1 &&
            !checkPropertiesForNull(greenhousesTwoColumnSection1, [
              "heading",
            ]) && (
              <PriceListCarousal
                bgColour="bg-light-grey"
                mobileImageHeight="20rem"
                title={greenhousesTwoColumnSection1?.heading}
                brochure={
                  greenhousesTwoColumnSection1?.rightColumn?.brochureLink
                }
                brochureImage={
                  greenhousesTwoColumnSection1?.rightColumn?.brochureImage.node
                }
                imageHeight="25rem"
                priceList={
                  greenhousesTwoColumnSection1?.rightColumn?.priceListLink
                }
                priceListImage={
                  greenhousesTwoColumnSection1?.rightColumn?.priceListImage
                    ?.node
                }
                leftColumnData={greenhousesTwoColumnSection1?.leftColumn}
              />
            )}

          {greenhousesTwoColumnSection2 &&
            !checkPropertiesForNull(greenhousesTwoColumnSection2, [
              "heading",
            ]) && (
              <ImageSide
                order="last"
                mobileImageHeight="20rem"
                svg={
                  <StaticImage
                    className="position-absolute d-none d-lg-block"
                    style={{ zIndex: 1, top: "-4rem", right: "-4rem" }}
                    quality="100"
                    src="../images/single-leaf.svg"
                    placeholder="blurred"
                    width={130}
                    formats={["auto", "webp"]}
                  />
                }
                svgPos="1rem"
                title={greenhousesTwoColumnSection2?.heading}
                text={
                  <SafeHtmlParser
                    htmlContent={greenhousesTwoColumnSection2?.description}
                  />
                }
                imageHeight="25rem"
                image={
                  greenhousesTwoColumnSection2.image?.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={greenhousesTwoColumnSection2.image?.node?.altText}
              />
            )}
          {greenhousesTwoColumnSection3 &&
            !checkPropertiesForNull(greenhousesTwoColumnSection3, [
              "heading",
            ]) && (
              <ImageSide
                bgColour="bg-light-grey"
                mobileImageHeight="20rem"
                title={greenhousesTwoColumnSection3?.heading}
                text={
                  <span>
                    <SafeHtmlParser
                      htmlContent={greenhousesTwoColumnSection3?.description}
                    />
                    {greenhousesTwoColumnSection3.cta &&
                      greenhousesTwoColumnSection3.cta.url && (
                        <Button
                          className="mt-4 w-100 w-md-auto"
                          as={Link}
                          to={greenhousesTwoColumnSection3?.cta.url}
                          variant="primary"
                        >
                          {greenhousesTwoColumnSection3?.cta.title}
                        </Button>
                      )}
                  </span>
                }
                imageHeight="25rem"
                image={
                  greenhousesTwoColumnSection3.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imageAlt={greenhousesTwoColumnSection3.image?.node?.altText}
              />
            )}
          {greenhousesPerfectionSection &&
            !checkPropertiesForNull(greenhousesPerfectionSection, [
              "heading",
            ]) && (
              <ThreeImageSection
                title={greenhousesPerfectionSection?.heading}
                text={
                  <div className="text-white">
                    <SafeHtmlParser
                      htmlContent={greenhousesPerfectionSection?.description}
                    />
                  </div>
                }
                imageHeight="15rem"
                images={greenhousesPerfectionSection.images}
                ctaButton={greenhousesPerfectionSection.cta}
              />
            )}

          <Faq
            imageHeight="25rem"
            page="Greenhouse"
            image={
              greenhousesFaqSection?.image?.node?.localFile.childImageSharp
                .gatsbyImageData
            }
            imageAlt={greenhousesFaqSection?.image?.node?.altText}
            heading={greenhousesFaqSection?.heading}
          />
          <Reviews
            topPadding="pt-5 pt-md-0"
            title={greenhousesReviewsSection?.heading}
          />

          <OpeningBanner heading={greenhousesOpeningHoursSection?.heading} />

          <ShowroomSection
            title={greenhousesCtaSection1?.heading}
            description={
              <SafeHtmlParser
                htmlContent={greenhousesCtaSection1?.description}
              />
            }
            ctaButton={greenhousesCtaSection1?.cta}
          />
        </Layout>
      </div>
    </>
  );
};

export default AboutUsPage;
